import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

export default function Navbar({ onSearch }) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearch(query);
  };

  const handleCancelSearch = () => {
    setSearchQuery('');
    onSearch(''); // Clear the search query
  };

  return (
    <>
      <nav className="navbar bg-body-tertiary">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src="maje.png" alt="Maje logo" />
          </Link>
          <Link to="https://www.instagram.com/wo.wali.link_?igsh=MWExM3lmeWRpY3FhYQ=="> 
            <button className='btn btn-danger d-flex m-auto follow'>Follow Us</button>
          </Link> 
          <form className="d-flex mt-4" role="search">
            <div className="searchBar">
              <input
                style={{ color: "#fff" }}
                value={searchQuery}
                onChange={handleInputChange}
                id="searchQueryInput"
                type="text"
                name="searchQueryInput"
                placeholder="Search by video No."
              />
              <button 
                id="searchQuerySubmit" 
                type="button" 
                name="searchQuerySubmit"
                onClick={searchQuery ? handleCancelSearch : null}
              >
                {searchQuery ? (
                  <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                <path
    fill="#666666"
    d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
  />         </svg>
                ) : (
                  <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                    <path
                      fill="#666666"
                      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                    />
                  </svg>
                  
                )}
              </button>
            </div>
          </form>
        </div>
      </nav>

      <ul className="nav-ul">
        <NavLink exact to="/" activeClassName="active-link">
          <li>All</li>
        </NavLink>
        <NavLink to="/stars" activeClassName="active-link">
          <li>Stars</li>
        </NavLink>
        <NavLink to="/indian" activeClassName="active-link">
          <li>Indians</li>
        </NavLink>
        <NavLink to="/hijabi" activeClassName="active-link">
          <li>Hijabi</li>
        </NavLink>
      </ul>
    </>
  );
}
