import AdminNav from "./AdminNav";

function AdminChannels() {
    return (  

        <>
        <AdminNav/>
         <h1>Hello posts</h1>
        </>
    );
}

export default AdminChannels;