import AdminNav from "./AdminNav";

function AdminTeraBox() {
    return (  

        <>
        <AdminNav/>
         <h1>On working</h1>
        </>
    );
}

export default AdminTeraBox;