import Navbar from "./Navbar";

function Channel() {
    return (
      <>
       <Navbar/>
        <h1>On working</h1>
      </>
    );
  }
  
  export default Channel;
  